import { FC } from "react";

import NextImage from "next/legacy/image";
import NextLink from "next/link";
import { useRouter } from "next/router";

import { Card, CardContent, Grid } from "@busbud/design-system-components";
import Heading from "@busbud/design-system-components/dist/Heading";
import { AngleRightMD } from "@busbud/design-system-components/dist/Icons";
import LayoutSection from "@busbud/design-system-components/dist/Layout/LayoutSection";
import Link from "@busbud/design-system-components/dist/Link";
import Stack from "@busbud/design-system-components/dist/Stack";
import makeStyles from "@busbud/design-system-components/dist/styles/makeStyles";
import Text from "@busbud/design-system-components/dist/Text";

import { ZendeskCategory } from "@services/zendesk/zendeskTypes";
import { urlBuilder } from "@utils/urlBuilder";
import { CATEGORIES } from "constants/categories";

import { SearchInput } from "./search-input";
import { CURVE_HEIGHT, Section } from "./section";

const useStyles = makeStyles(({ busbud: { spacing, breakpoints } }) => ({
  heading: {
    marginBottom: spacing(2),
  },
  section: {
    paddingTop: spacing(3),
    [breakpoints.up("sm")]: {
      paddingTop: spacing(5),
    },
  },
  search: {
    marginBottom: spacing(5),
    [breakpoints.up("md")]: {
      marginBottom: spacing(10),
    },
  },
  description: {
    marginBottom: spacing(2),
    [breakpoints.up("sm")]: {
      marginBottom: spacing(3.5),
    },
  },
}));

const useCategoriesListStyles = makeStyles(
  ({ busbud: { spacing, breakpoints } }) => ({
    categoriesWrapper: {
      overflowY: "scroll",
      scrollbarWidth: "none",
      "-ms-overflow-style": "none",
      "&::-webkit-scrollbar": {
        display: "none",
      },
      flexWrap: "nowrap",
      paddingBottom: `calc(${spacing(5)} + ${CURVE_HEIGHT})`,
      [breakpoints.up("sm")]: {
        overflowY: "unset",
        flexWrap: "wrap",
        paddingBottom: `calc(${spacing(10)} + ${CURVE_HEIGHT})`,
      },
    },
    heading: {
      marginBottom: spacing(2),
      [breakpoints.up("sm")]: {
        marginBottom: spacing(3.5),
      },
    },
  })
);

const useCategoryCardStyles = makeStyles(
  ({ busbud: { spacing, colors, breakpoints, styles } }) => ({
    item: {
      perspective: "1000px",
      perspectiveOrigin: "top",
      "&:hover": {
        textDecoration: "none",
      },
    },
    categoryCard: {
      height: 180,
      width: 130,
      minWidth: 130,
      transition: "0.25s cubic-bezier(0.34, 1.56, 0.64, 1)",
      "&:hover": {
        transform: "translate3d(0, -4px, 12px)",
        boxShadow: styles.shadow.subtle.md,
      },
      [breakpoints.up("sm")]: {
        height: 248,
        width: 192,
      },
    },
    cardContent: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    },
    categoryButton: {
      background: colors.background.primary.transparent,
      borderRadius: styles.rounding.md,
      marginLeft: "auto",
      padding: spacing(0.5),
      marginTop: spacing(0.5),
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    heading: {
      marginBottom: spacing(2),
    },
  })
);

interface Props {
  categories: ZendeskCategory[];
  translations: FooterCategoriesTranslationsFragment &
    SearchInputTranslationsFragment;
  withSearch?: boolean;
}

export const FooterCategories: FC<Props> = ({
  translations,
  categories,
  withSearch,
}) => {
  const classes = useStyles();

  return (
    <Section
      color="background.secondary.opaque"
      curveDirection="reverse"
      className={classes.section}
      noPadding
    >
      {withSearch && (
        <LayoutSection gutters={4}>
          <Stack direction="column" className={classes.search}>
            <Heading
              component="h3"
              className={classes.heading}
              size={{ xs: "sm", sm: "md" }}
              align="center"
              display="block"
              color="text.secondary.opaque"
            >
              {translations.footerSearchTitle}
            </Heading>
            {!!translations.footerSearchDescription && (
              <Text
                size="lg"
                color="text.tertiary.opaque"
                align="center"
                className={classes.description}
              >
                {translations.footerSearchDescription}
              </Text>
            )}
            <SearchInput translations={translations} />
          </Stack>
        </LayoutSection>
      )}
      <CategoriesList translations={translations} categories={categories} />
    </Section>
  );
};

const CategoriesList: FC<Props> = ({ translations, categories }) => {
  const classes = useCategoriesListStyles();

  return (
    <div>
      <Heading
        component="h3"
        className={classes.heading}
        size={{ xs: "sm", sm: "md" }}
        align="center"
        display="block"
      >
        {translations.footerCategoriesTitle}
      </Heading>
      <Grid
        direction="row"
        justifyContent={{ xs: "flex-start", sm: "center" }}
        spacing={2}
        className={classes.categoriesWrapper}
        container
      >
        {categories.map((category) => {
          return <CategoryCard key={category.id} {...category} />;
        })}
      </Grid>
    </div>
  );
};

const CategoryCard: FC<ZendeskCategory> = ({ id, name }) => {
  const classes = useCategoryCardStyles();
  const { locale = "" } = useRouter();

  return (
    <Grid item>
      <NextLink
        prefetch={false}
        href={urlBuilder.category(locale, id, name)}
        passHref
        legacyBehavior
      >
        <Link className={classes.item}>
          <Card
            style={{
              border: "none",
              background: id ? CATEGORIES[id].backgroundColorDark : "",
            }}
            boxShadow="lg"
            data-cy="category-card"
            className={classes.categoryCard}
          >
            <CardContent className={classes.cardContent}>
              <Heading
                component="h4"
                size={{ xs: "xs", md: "sm" }}
                color={
                  id ? CATEGORIES[id].textColor : "text.overBackground.opaque"
                }
                className={classes.heading}
              >
                {name}
              </Heading>
              <NextImage
                src={`/help-center/${CATEGORIES[id].image}`}
                height={100}
                width={140}
                layout="responsive"
                objectFit="contain"
              />
              <div className={classes.categoryButton}>
                <AngleRightMD color="palette.static.white" />
              </div>
            </CardContent>
          </Card>
        </Link>
      </NextLink>
    </Grid>
  );
};
