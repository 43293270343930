import { FC } from "react";

import NextLink from "next/link";
import { useRouter } from "next/router";

import BrandLogo from "@busbud/design-system-components/dist/BrandLogo/BrandLogo";
import IconButton from "@busbud/design-system-components/dist/IconButton";
import { GlobeMD } from "@busbud/design-system-components/dist/Icons";
import FacebookLG from "@busbud/design-system-components/dist/Icons/Facebook/LG";
import InstagramLG from "@busbud/design-system-components/dist/Icons/Instagram/LG";
import TwitterLG from "@busbud/design-system-components/dist/Icons/Twitter/LG";
import Link from "@busbud/design-system-components/dist/Link";
import Stack from "@busbud/design-system-components/dist/Stack";
import makeStyles from "@busbud/design-system-components/dist/styles/makeStyles";

import { LocalePicker } from "@components/locale-picker";
import { tracking } from "@services/tracking";
import { columns } from "@utils/style";
import { urlBuilder } from "@utils/urlBuilder";

const useStyles = makeStyles(({ busbud: { breakpoints, spacing } }) => ({
  main: {
    padding: spacing(3, 0),
    "& > :not(:last-child)": {
      flex: 1,
    },
    [breakpoints.up("sm")]: {
      padding: spacing(5, 0),
    },
    [breakpoints.up("md")]: {
      "& > :not(:last-child)": {
        flexBasis: columns(7),
      },
    },
  },
  brand: {
    paddingRight: columns(1),
    [breakpoints.up("md")]: {
      paddingRight: 0,
    },
  },
}));

type Props = {
  translations: FooterNavigationTranslationsFragment &
    LocalePickerTranslationsFragment;
};

export const FooterNavigation: FC<Props> = ({ translations }) => {
  const classes = useStyles();
  const { locale = "", asPath } = useRouter();

  return (
    <Stack
      className={classes.main}
      spacing={{ xs: 2, sm: 0 }}
      direction={{ xs: "column", sm: "row" }}
      component="footer"
    >
      <Stack
        className={classes.brand}
        direction="column"
        spacing={1}
        alignItems="flex-start"
      >
        <BrandLogo />
        <Stack ml={-1.5}>
          <NextLink prefetch={false} href={urlBuilder.facebook} legacyBehavior>
            <IconButton size="lg" variant="text" color="tertiary">
              <FacebookLG />
            </IconButton>
          </NextLink>
          <NextLink prefetch={false} href={urlBuilder.instagram} legacyBehavior>
            <IconButton size="lg" variant="text" color="tertiary">
              <InstagramLG />
            </IconButton>
          </NextLink>
          <NextLink prefetch={false} href={urlBuilder.twitter} legacyBehavior>
            <IconButton size="lg" variant="text" color="tertiary">
              <TwitterLG />
            </IconButton>
          </NextLink>
        </Stack>
      </Stack>
      <Stack
        direction="column"
        spacing={2}
        alignItems="flex-start"
        pt={{ xs: 0, sm: 1 }}
      >
        <NextLink
          prefetch={false}
          href={urlBuilder.whoWeAre(locale)}
          passHref
          legacyBehavior
        >
          <Link size="lg" fontWeight="bold" noWrap>
            {translations.navItemWhoWeAre}
          </Link>
        </NextLink>
        <NextLink
          prefetch={false}
          href={urlBuilder.careers(locale)}
          passHref
          legacyBehavior
        >
          <Link size="lg" fontWeight="bold" noWrap>
            {translations.navItemCareers}
          </Link>
        </NextLink>
        <NextLink
          prefetch={false}
          href={urlBuilder.newsroom(locale)}
          passHref
          legacyBehavior
        >
          <Link size="lg" fontWeight="bold" noWrap>
            {translations.navItemNewsroom}
          </Link>
        </NextLink>
      </Stack>
      <Stack
        direction="column"
        spacing={2}
        alignItems="flex-start"
        pt={{ xs: 0, sm: 1 }}
      >
        <NextLink
          prefetch={false}
          href={urlBuilder.blog(locale)}
          passHref
          legacyBehavior
        >
          <Link size="lg" fontWeight="bold" noWrap>
            {translations.navItemBlog}
          </Link>
        </NextLink>
        <NextLink
          prefetch={false}
          href={urlBuilder.contact(asPath)}
          shallow
          replace
          passHref
          legacyBehavior
        >
          <Link
            onClick={() => tracking.clickHelpButton("contact", locale)}
            size="lg"
            fontWeight="bold"
            noWrap
          >
            {translations.navItemContact}
          </Link>
        </NextLink>
      </Stack>
      <Stack
        direction="column"
        spacing={2}
        alignItems={{ xs: "flex-start", sm: "flex-end" }}
        pt={{ xs: 2, sm: 1 }}
        ml={{ xs: -1, sm: 0 }}
      >
        <LocalePicker translations={translations}>
          <LocalePicker.Button startIcon={<GlobeMD />} />
        </LocalePicker>
        <></>
      </Stack>
    </Stack>
  );
};
