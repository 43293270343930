import React from "react";

import parse, {
  DOMNode,
  domToReact,
  Element,
  HTMLReactParserOptions,
} from "html-react-parser";
import Image from "next/legacy/image";
import NextLink from "next/link";
import { useRouter } from "next/router";

import Card from "@busbud/design-system-components/dist/Card";
import Heading from "@busbud/design-system-components/dist/Heading";
import Link from "@busbud/design-system-components/dist/Link";
import makeStyles from "@busbud/design-system-components/dist/styles/makeStyles";
import Table from "@busbud/design-system-components/dist/Table";
import TableCell from "@busbud/design-system-components/dist/TableCell";
import TableContainer from "@busbud/design-system-components/dist/TableContainer";
import TableRow from "@busbud/design-system-components/dist/TableRow";
import Text from "@busbud/design-system-components/dist/Text";

type Align = "left" | "right" | "center" | undefined;

function getAlignValueFromAttrs(attrs: Element["attribs"]): Align {
  const align = attrs?.class?.split("wysiwyg-text-align-")[1]?.split(" ")[0];
  return align as Align;
}

function getColspanValueFromAttrs(attrs: Element["attribs"]): number {
  return attrs.colspan ? parseInt(attrs.colspan) : 1;
}

export const useStyles = makeStyles(({ busbud: { spacing } }) => ({
  root: {
    "&:first-child": {
      marginTop: 0,
    },
    "& a": {
      whiteSpace: "pre-wrap",
    },
  },
  p: {
    "&:not(:blank)": {
      marginBottom: spacing(2),
    },
  },
  heading: {
    margin: spacing(2, 0),
  },
  ul: {
    listStyle: "initial",
    paddingLeft: spacing(2),
    marginBottom: spacing(2),
  },
  ol: {
    listStyle: "decimal",
    paddingLeft: spacing(2),
    marginBottom: spacing(2),
  },
  table: {
    "& p": {
      marginBottom: 0,
    },
  },
  tableCell: {
    padding: spacing(2),
  },
}));

const FORM_MAPPER: Record<string, Enum_Hcform_Formcomponent> = {
  cancelation: "cancel_tickets",
  exchange: "exchange_tickets",
  failed_transaction: "failed_booking",
  ticket_not_received: "resend_confirmation",
  boarding: "find_boarding_information",
  luggage: "find_boarding_information",
  route_missing: "trip_not_available",
  review_report: "trip_feedback",
  gdpr_request: "personal_data",
  other: "contact",
};

const getLinkProps = (href = "") => {
  const isOldSupport = /https?:\/\/www\.busbud\.com\/\w{2}(-\w{2})?\/support/.test(
    href
  );

  const isNewSupport =
    /^\??form=/.test(href) || /\/help\.busbud\.com.*\?form=/.test(href);

  if (isNewSupport) {
    return { form: href.substring(href.lastIndexOf("=") + 1) };
  }

  const isAnAnchor = href.startsWith("#");

  if (isAnAnchor) {
    return { hash: href };
  }

  if (!isOldSupport) {
    return { href };
  }

  const queryParams = new URLSearchParams(href.split("?")[1]);
  const selectedScenario = queryParams.get("selected_scenario");
  const form = selectedScenario && FORM_MAPPER[selectedScenario];

  if (!form) {
    return { href: "/" };
  }

  return { form };
};

export const ZendeskHtmlParser: React.FC<{ body: string }> = ({ body }) => {
  const classes = useStyles();
  const { pathname, query } = useRouter();

  const options: HTMLReactParserOptions = {
    replace: (node: DOMNode) => {
      const { name, children, attribs } = node as Element;
      const { class: _class, ...props } = attribs || {};

      switch (name) {
        case "h1":
          delete props.style;
          return (
            <Heading
              {...props}
              className={classes.heading}
              component="h2"
              size="sm"
              align={getAlignValueFromAttrs(attribs)}
            >
              {domToReact(children, options)}
            </Heading>
          );
        case "h2":
          delete props.style;
          return (
            <Heading
              {...props}
              className={classes.heading}
              component="h2"
              size="sm"
              align={getAlignValueFromAttrs(attribs)}
            >
              {domToReact(children, options)}
            </Heading>
          );
        case "h3":
          delete props.style;
          return (
            <Heading
              {...props}
              className={classes.heading}
              component="h3"
              size="sm"
              align={getAlignValueFromAttrs(attribs)}
            >
              {domToReact(children, options)}
            </Heading>
          );
        case "h4":
          delete props.style;
          return (
            <Heading
              {...props}
              className={classes.heading}
              component="h4"
              size="sm"
              align={getAlignValueFromAttrs(attribs)}
            >
              {domToReact(children, options)}
            </Heading>
          );
        case "h5":
          delete props.style;
          return (
            <Heading
              {...props}
              className={classes.heading}
              component="h5"
              size="sm"
              align={getAlignValueFromAttrs(attribs)}
            >
              {domToReact(children, options)}
            </Heading>
          );
        case "h6":
          delete props.style;
          return (
            <Heading
              {...props}
              className={classes.heading}
              component="h6"
              size="sm"
              align={getAlignValueFromAttrs(attribs)}
            >
              {domToReact(children, options)}
            </Heading>
          );
        case "p":
          return (
            <Text
              className={classes.p}
              size="lg"
              align={getAlignValueFromAttrs(attribs)}
            >
              {domToReact(children, options)}
            </Text>
          );
        case "a": {
          const { href, form, hash } = getLinkProps(attribs.href);

          // If there is no href, it's a link to an anchor or a query for a form. We use a shallow link
          if (!href) {
            return (
              <NextLink
                passHref
                href={{
                  pathname,
                  query: {
                    ...query,
                    ...(form ? { form } : {}),
                  },
                  hash,
                }}
                shallow={true}
                prefetch={false}
                legacyBehavior
              >
                <Link size="lg">{domToReact(children, options)}</Link>
              </NextLink>
            );
          }

          if (href.startsWith("http")) {
            const hrefURL = new URL(href);
            const hostname =
              typeof window !== "undefined" && window.location.hostname;
            const isExternal = hrefURL.host !== hostname;

            // If it's an external link, we use a normal link
            if (isExternal) {
              return (
                <Link size="lg" href={href} target={attribs.target || "_self"}>
                  {domToReact(children, options)}
                </Link>
              );
            }
          }

          return (
            <NextLink passHref href={href} prefetch={false} legacyBehavior>
              <Link size="lg">{domToReact(children, options)}</Link>
            </NextLink>
          );
        }

        case "ul":
          return (
            <ul className={classes.ul}>{domToReact(children, options)}</ul>
          );
        case "li":
          return (
            <Text component="li" size="lg">
              {domToReact(children, options)}
            </Text>
          );
        case "table":
          return (
            <TableContainer component={Card} className={classes.table}>
              <Table>{domToReact(children, options)}</Table>
            </TableContainer>
          );
        case "tr":
          return <TableRow>{domToReact(children, options)}</TableRow>;
        case "td":
          return (
            <TableCell
              align={getAlignValueFromAttrs(attribs)}
              colSpan={getColspanValueFromAttrs(attribs)}
              className={classes.tableCell}
            >
              {domToReact(children, options)}
            </TableCell>
          );
        case "img":
          return (
            <Image
              layout={
                attribs.height && attribs.width ? "intrinsic" : "responsive"
              }
              height={+attribs.height || 100}
              width={+attribs.width || 200}
              alt={attribs.alt}
              src={attribs.src}
              loader={({ src }) => src}
              unoptimized
            />
          );
      }
    },
  };

  if (!body) {
    return null;
  }

  return <div className={classes.root}>{parse(body, options)}</div>;
};
